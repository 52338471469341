/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CountOpenInspections } from '../models/count-open-inspections';
import { OpenInspection } from '../models/open-inspection';
import { Page } from '../models/page';
import { SaveAndCompleteInspectionResultDto } from '../models/save-and-complete-inspection-result-dto';
import { SortOrder } from '../models/sort-order';

@Injectable({ providedIn: 'root' })
export class OpenInspectionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `countAllOpen()` */
  static readonly CountAllOpenPath = '/open-inspections/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countAllOpen()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAllOpen$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CountOpenInspections>> {
    const rb = new RequestBuilder(this.rootUrl, OpenInspectionsService.CountAllOpenPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CountOpenInspections>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countAllOpen$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAllOpen(
    params?: {
    },
    context?: HttpContext
  ): Observable<CountOpenInspections> {
    return this.countAllOpen$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountOpenInspections>): CountOpenInspections => r.body)
    );
  }

  /** Path part for operation `findOpenInspections()` */
  static readonly FindOpenInspectionsPath = '/open-inspections/open';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOpenInspections()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOpenInspections$Response(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;

    /**
     * Format "YYYY-MM-DD"
     */
      startDate?: string;

    /**
     * Format "YYYY-MM-DD"
     */
      endDate?: string;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<OpenInspection>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, OpenInspectionsService.FindOpenInspectionsPath, 'get');
    if (params) {
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<OpenInspection>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOpenInspections$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOpenInspections(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;

    /**
     * Format "YYYY-MM-DD"
     */
      startDate?: string;

    /**
     * Format "YYYY-MM-DD"
     */
      endDate?: string;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<OpenInspection>;
}> {
    return this.findOpenInspections$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<OpenInspection>;
}>): Page & {
'result': Array<OpenInspection>;
} => r.body)
    );
  }

  /** Path part for operation `completeOpenInspection()` */
  static readonly CompleteOpenInspectionPath = '/open-inspections/open/{inspectionId}/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeOpenInspection()` instead.
   *
   * This method doesn't expect any request body.
   */
  completeOpenInspection$Response(
    params: {
      inspectionId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OpenInspection>> {
    const rb = new RequestBuilder(this.rootUrl, OpenInspectionsService.CompleteOpenInspectionPath, 'post');
    if (params) {
      rb.path('inspectionId', params.inspectionId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OpenInspection>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `completeOpenInspection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  completeOpenInspection(
    params: {
      inspectionId: number;
    },
    context?: HttpContext
  ): Observable<OpenInspection> {
    return this.completeOpenInspection$Response(params, context).pipe(
      map((r: StrictHttpResponse<OpenInspection>): OpenInspection => r.body)
    );
  }

  /** Path part for operation `savenOrCompleteInspectionResult()` */
  static readonly SavenOrCompleteInspectionResultPath = '/open-inspections/open/{inspectionId}/task/saveOrComplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savenOrCompleteInspectionResult()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savenOrCompleteInspectionResult$Response(
    params: {
      inspectionId: number;
      body: SaveAndCompleteInspectionResultDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OpenInspection>> {
    const rb = new RequestBuilder(this.rootUrl, OpenInspectionsService.SavenOrCompleteInspectionResultPath, 'post');
    if (params) {
      rb.path('inspectionId', params.inspectionId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OpenInspection>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `savenOrCompleteInspectionResult$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savenOrCompleteInspectionResult(
    params: {
      inspectionId: number;
      body: SaveAndCompleteInspectionResultDto
    },
    context?: HttpContext
  ): Observable<OpenInspection> {
    return this.savenOrCompleteInspectionResult$Response(params, context).pipe(
      map((r: StrictHttpResponse<OpenInspection>): OpenInspection => r.body)
    );
  }

}
