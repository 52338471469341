/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AssignPropertiesToUser } from '../models/assign-properties-to-user';
import { CreateUserDto } from '../models/create-user-dto';
import { InspectionInfo } from '../models/inspection-info';
import { Page } from '../models/page';
import { SortOrder } from '../models/sort-order';
import { UnassignPropertiesFromUser } from '../models/unassign-properties-from-user';
import { UpdateUserDeputyStatus } from '../models/update-user-deputy-status';
import { UpdateUserDto } from '../models/update-user-dto';
import { UpdateUserStatus } from '../models/update-user-status';
import { User } from '../models/user';
import { UserProperty } from '../models/user-property';
import { UserPropertyMode } from '../models/user-property-mode';
import { UserTimeReport } from '../models/user-time-report';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pageUsers()` */
  static readonly PageUsersPath = '/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageUsers$Response(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<User>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.PageUsersPath, 'get');
    if (params) {
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<User>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageUsers(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<User>;
}> {
    return this.pageUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<User>;
}>): Page & {
'result': Array<User>;
} => r.body)
    );
  }

  /** Path part for operation `createUser()` */
  static readonly CreateUserPath = '/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser$Response(
    params: {
      body: CreateUserDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.CreateUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser(
    params: {
      body: CreateUserDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.createUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `findOne()` */
  static readonly FindOnePath = '/users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne$Response(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<User>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.FindOnePath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<User> {
    return this.findOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `removeUser()` */
  static readonly RemoveUserPath = '/users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUser$Response(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.RemoveUserPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUser(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.removeUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateUser()` */
  static readonly UpdateUserPath = '/users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(
    params: {
      userId: number;
      body: UpdateUserDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<User>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UpdateUserPath, 'patch');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(
    params: {
      userId: number;
      body: UpdateUserDto
    },
    context?: HttpContext
  ): Observable<User> {
    return this.updateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `findUserProperties()` */
  static readonly FindUserPropertiesPath = '/users/{userId}/properties';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findUserProperties()` instead.
   *
   * This method doesn't expect any request body.
   */
  findUserProperties$Response(
    params: {
      userId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      mode?: UserPropertyMode;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<UserProperty>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.FindUserPropertiesPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('mode', params.mode, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<UserProperty>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findUserProperties$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findUserProperties(
    params: {
      userId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      mode?: UserPropertyMode;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<UserProperty>;
}> {
    return this.findUserProperties$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<UserProperty>;
}>): Page & {
'result': Array<UserProperty>;
} => r.body)
    );
  }

  /** Path part for operation `assignPropertiesToUser()` */
  static readonly AssignPropertiesToUserPath = '/users/{userId}/properties/assign';

  /**
   * Assign the properties to the user. These properties will be visible in the app.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignPropertiesToUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignPropertiesToUser$Response(
    params: {
      userId: number;
      body: AssignPropertiesToUser
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.AssignPropertiesToUserPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Assign the properties to the user. These properties will be visible in the app.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignPropertiesToUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignPropertiesToUser(
    params: {
      userId: number;
      body: AssignPropertiesToUser
    },
    context?: HttpContext
  ): Observable<number> {
    return this.assignPropertiesToUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `unassignPropertiesToUser()` */
  static readonly UnassignPropertiesToUserPath = '/users/{userId}/properties/unassign';

  /**
   * Unassign the properties from the user. This also deletes open inspections and tasks assigned to this user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unassignPropertiesToUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unassignPropertiesToUser$Response(
    params: {
      userId: number;
      body: UnassignPropertiesFromUser
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UnassignPropertiesToUserPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Unassign the properties from the user. This also deletes open inspections and tasks assigned to this user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unassignPropertiesToUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unassignPropertiesToUser(
    params: {
      userId: number;
      body: UnassignPropertiesFromUser
    },
    context?: HttpContext
  ): Observable<number> {
    return this.unassignPropertiesToUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findUserInspections()` */
  static readonly FindUserInspectionsPath = '/users/{userId}/inspections';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findUserInspections()` instead.
   *
   * This method doesn't expect any request body.
   */
  findUserInspections$Response(
    params: {
      userId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;

    /**
     * Format "YYYY-MM-DD"
     */
      startDate?: string;

    /**
     * Format "YYYY-MM-DD"
     */
      endDate?: string;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<InspectionInfo>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.FindUserInspectionsPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<InspectionInfo>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findUserInspections$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findUserInspections(
    params: {
      userId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;

    /**
     * Format "YYYY-MM-DD"
     */
      startDate?: string;

    /**
     * Format "YYYY-MM-DD"
     */
      endDate?: string;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<InspectionInfo>;
}> {
    return this.findUserInspections$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<InspectionInfo>;
}>): Page & {
'result': Array<InspectionInfo>;
} => r.body)
    );
  }

  /** Path part for operation `updateUserStatus()` */
  static readonly UpdateUserStatusPath = '/users/{userId}/status';

  /**
   * Changes the user status and deactivates assigned tasks when user will be disabled.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserStatus$Response(
    params: {
      userId: number;
      body: UpdateUserStatus
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<User>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UpdateUserStatusPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Changes the user status and deactivates assigned tasks when user will be disabled.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserStatus(
    params: {
      userId: number;
      body: UpdateUserStatus
    },
    context?: HttpContext
  ): Observable<User> {
    return this.updateUserStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `updateUserDeputyStatus()` */
  static readonly UpdateUserDeputyStatusPath = '/users/{userId}/deputy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserDeputyStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserDeputyStatus$Response(
    params: {
      userId: number;
      body: UpdateUserDeputyStatus
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<User>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UpdateUserDeputyStatusPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserDeputyStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserDeputyStatus(
    params: {
      userId: number;
      body: UpdateUserDeputyStatus
    },
    context?: HttpContext
  ): Observable<User> {
    return this.updateUserDeputyStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `userTimeReport()` */
  static readonly UserTimeReportPath = '/users/{userId}/timeReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userTimeReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  userTimeReport$Response(
    params: {
      userId: number;
      body: UserTimeReport
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UserTimeReportPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/pdf', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userTimeReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  userTimeReport(
    params: {
      userId: number;
      body: UserTimeReport
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.userTimeReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
