import {
  EditChecklistStateModel,
  EditCheckListState,
} from './edit-checklist/edit-checklist.state';
import { CategoryState, CategoryStateModel } from './category/category.state';
import { PriorityState, PriorityStateModel } from './priority/priority.state';

export interface AppState {
  category: CategoryStateModel;
  priority: PriorityStateModel;
  editChecklist: EditChecklistStateModel;
}

// add app state here!
export const APP_STATES = [CategoryState, PriorityState, EditCheckListState];
